import React, { useState } from 'react';
// @ts-ignore
import house from '../../../assets/audio/house_master.mp3';
// @ts-ignore
import edge from '../../../assets/audio/edge_unmastered.mp3';
// @ts-ignore
import dnb from '../../../assets/audio/break.mp3';
// @ts-ignore
import dnbDrums from '../../../assets/audio/dnb_drop_drums.mp3';
import houseProject from '../../../assets/pictures/projects/audio/houseProject.png';
import dnbDrumsProject from '../../../assets/pictures/projects/audio/dnbDrumsProject.png';
import { MusicPlayer } from '../../general';

export interface MusicProjectsProps {}

const MusicProjects: React.FC<MusicProjectsProps> = (props) => {
    const [currentSong, setCurrentSong] = useState<string>('');

    return (
        <div className="site-page-content">
            <h1>Music & Sound</h1>
            <h3>Ventures</h3>
            <br />
            <div className="text-block">
                <p>
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
                </p>
                <br />
                <p>
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
                </p>
                <br />
                <p>
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. 
                </p>
            </div>
            <h2>■■■■■■■■■■■■</h2>
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
            </p>
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
            </p>
            <br />

            <MusicPlayer
                src={house}
                title="■■■■■■■"
                subtitle="■■■■■■■■■■■■ - ■■■■■■■■"
                currentSong={currentSong}
                setCurrentSong={setCurrentSong}
            />

            <br />
            <br />
            <div className="captioned-image">
                <img src={houseProject} alt="" />
                <p>
                    <sub>
                        <b>Figure 1:</b> ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                        ■■■■■■■■. ■■■■■■■■■■■■■■■■.
                    </sub>
                </p>
            </div>
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■,
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■
                ■■■■■■■■■■.
            </p>
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
                ■■■■■■■■■■■■■■■■■■■■
            </p>
            <br />
            <h2>■■■■■■■■■■</h2>
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■,
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■
                
            </p>
            <br />
            <MusicPlayer
                src={edge}
                title="■■■■■■■■■■■■■■"
                subtitle="■■■■■■■■■■■■■■ - 202■"
                currentSong={currentSong}
                setCurrentSong={setCurrentSong}
            />
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■
            </p>
            <br />
            <h2>My love for Drum & Bass</h2>
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
            </p>
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
            </p>
            <br />
            <MusicPlayer
                src={dnb}
                title="■■■■■■■■■■■■"
                subtitle="■■■■■■■■■■■■ - 201■/202■"
                currentSong={currentSong}
                setCurrentSong={setCurrentSong}
            />
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■
            </p>
            <br />
            <MusicPlayer
                src={dnbDrums}
                title="■■■■■■■■"
                subtitle="■■■■■■■■ - 201■■■■"
                currentSong={currentSong}
                setCurrentSong={setCurrentSong}
            />
            <br />
            <div className="captioned-image">
                <img src={dnbDrumsProject} alt="" />
                <p>
                    <sub>
                        <b>Figure 2:</b> ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■
                        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■!
                    </sub>
                </p>
            </div>
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
            </p>
            <br />
            <h2>■■■■■■■■■■■■■■■</h2>
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
            </p>
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■
            </p>
            <br />
        </div>
    );
};

// const styles: StyleSheetCSS = {};

export default MusicProjects;
