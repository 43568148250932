import React from 'react';
// @ts-ignore
import saga from '../../../assets/pictures/projects/software/saga.mp4';
// @ts-ignore
import computer from '../../../assets/pictures/projects/software/computer.mp4';
// @ts-ignore
import scroll from '../../../assets/pictures/projects/software/scroll.mp4';
import ResumeDownload from '../ResumeDownload';
import VideoAsset from '../../general/VideoAsset';

export interface SoftwareProjectsProps {}

const SoftwareProjects: React.FC<SoftwareProjectsProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>Website</h1>
            <h3>Projects</h3>
            <br />
            <p>
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                ■■■■■■■■■■■■■■■■■■■■■■■■■■■.
            </p>
            <br />
            <ResumeDownload />
            <br />
            <div className="text-block">
                <h2>■■■■■■■■■■■■■■■■■■</h2>
                <br />
                <p>
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■, ■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    creatively. ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■.■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■, it's
                    ■■■■■■■■■!
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={computer} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 1:</b> ■■■■■■■■■■■■■■■■■■.
                            ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                            ■■■■■■■■■.
                        </sub>
                    </p>
                </div>
                <p>
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■, ■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://henryheffernan.vercel.app/"
                    >
                        here
                    </a>{' '}
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■■■■■■■■
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://■■■■■■■■■■■■■■■.com"
                        >
                            <p>
                                <b>[■■■■■■]</b> - ■■■■■■■■
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://■■■■■■/"
                        >
                            <p>
                                <b>[■■■■■■]</b> - ■■■■■■■■■■■■■■■■■■
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://■■■■■■■■■■■■"
                        >
                            <p>
                                <b>[■■■■■■]</b> - ■■■■■■■■■■■■
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://■■■■■■■■■■■■"
                        >
                            <p>
                                <b>[■■■■■■]</b> - ■■■■■■■■■■■■
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/■■■■■■■■■■■■"
                    >
                        @■■■■■■■■
                    </a>
                </p>
            </div>
            <div className="text-block">
                <h2>■■■■■■■■■■■■■■■■■■■■■■■■</h2>
                <br />
                <p>
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={saga} />
                    <div style={styles.caption}>
                        <p>
                            <sub>
                                <b>Figure 2: </b> ■■■■■■■■■■■■■■■■■■■■■■■■■■■
                                ■■■■■■■■■, ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                                ■■■■■■■■■■■■■■■■■■■■■■■■■■■.
                            </sub>
                        </p>
                    </div>
                </div>
                <p>
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
                </p>
                <br />
                <h3>■■■■■■■■</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://■■■■■■■■"
                        >
                            <p>
                                <b>[■■■■■■■■]</b> - ■■■■■■■■■■■■■■■■■■■■
                                ■■■■■■■■■■■■
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://■■■■■■■■■■■■"
                        >
                            <p>
                                <b>[■■■■■■■■]</b> - ■■■■■■■■■■■■■■■■■■■■■■■■
                                ■■■■■■■■■■■■
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                    ■■■■■■■■■■■■, ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■, ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
                </p>
            </div>
            <div className="text-block">
                <h2>■■■■■■■■■■■■</h2>
                <br />
                <p>
                   ■■■■■■■■■■■■■■■■■■■■, ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                   ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■. ■■■■■■■■■■■■
                   ■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={scroll} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 3:</b> ■■■■■■■■■■■■■■■■■■■■■■■■, ■■■■■■
                            ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                            ■■■■■■
                        </sub>
                    </p>
                </div>
                <p>
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                </p>
                <br />
                <h3>■■■■■■</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://■■■■■■"
                        >
                            <p>
                                <b>[■■■■■■]</b> - ■■■■■■■■■■■■■■■■■■■■■■■■■■■
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://■■■■■■■■■■■■"
                        >
                            <p>
                                <b>[■■■■■■■■■■■■]</b> - ■■■■■■■■■■■■■■■■■■
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    "■■■■■■■■■■■■■■■■■■", ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                    ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■.
                </p>
            </div>
            <ResumeDownload />
        </div>
    );
};

const styles: StyleSheetCSS = {
    video: {
        width: '100%',
        padding: 12,
    },
    caption: {
        width: '80%',
    },
};

export default SoftwareProjects;
